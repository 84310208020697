$(function() {
  /**
   * 検索結果ページに渡す値を変更する。
   * 
   * PC・スマホで渡す値(name=design)が変わる。
   * レンスポンシブの場合、値を変える必要がなく処理は不要。
   * 前提: 値を変える場合、input[name=design]にjs-syncsearch-valueクラスをつけて、それぞれの値をdata属性で設定しておく。
   */
  function changeSyncsearchValue() {
    var $input = $('.js-syncsearch-value[name="design"]');
    if ($input.length == 0) {
      return;
    }
    var dataName = (window.innerWidth < 1024) ? 'design-sp' : 'design-pc';
    $input.val($input.data(dataName));
  }
  changeSyncsearchValue();
});